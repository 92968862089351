// extracted by mini-css-extract-plugin
export var component = "styles-module--component--lZfUp";
export var image = "styles-module--image--H8hdY";
export var item = "styles-module--item--0UEzx";
export var itemsWrap = "styles-module--items-wrap--ELW86";
export var line = "styles-module--line--RPqx7";
export var next = "styles-module--next--eq2j8";
export var pretitle = "styles-module--pretitle--ey5V6";
export var prev = "styles-module--prev--NUUWq";
export var text = "styles-module--text--MK+IG";
export var title = "styles-module--title--Tn71i";