import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page } = data
  let content = page.frontmatter[locale]
  content.title = content.name
  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        pageId={page.id}
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          slug
          name
          bio
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          jobTitle
          jobTitle2
          seo {
            title
            description
            keywords
          }
        }
        zh {
          slug
          name
          bio
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          jobTitle
          jobTitle2
          seo {
            title
            description
            keywords
          }
        }
      }
    }
  }
`
