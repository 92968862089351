import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Pagination from 'blocks/Pagination/template'
import { getPaginationItems } from 'js/utils'

const Block = ({ content, data, locale }) => {
  const strings = {
    en: {
      previous: 'Previous',
      next: 'Next',
    },
    zh: {
      previous: '前一个',
      next: '下一个',
    },
  }
  const tr = strings[locale]

  const { partner } = content
  const partners = data?.partners?.nodes
  const partnersLocale = partners?.map((item) => item.frontmatter[locale])
  const paginationItems = getPaginationItems(partner, partnersLocale)
  const paginationContent = {
    prev: {
      pretitle: tr.previous,
      title: paginationItems.prevItem.name,
      subtitle: paginationItems.prevItem.jobTitle,
      image: paginationItems.prevItem.image,
      link: `/partners/${paginationItems.prevItem.slug}/`,
    },
    next: {
      pretitle: tr.next,
      title: paginationItems.nextItem.name,
      subtitle: paginationItems.nextItem.jobTitle,
      image: paginationItems.nextItem.image,
      link: `/partners/${paginationItems.nextItem.slug}/`,
    },
  }

  return <Pagination content={paginationContent} />
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          partners: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "partner" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  name
                  jobTitle
                  jobTitle2
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  order
                  slug
                  name
                  jobTitle
                  jobTitle2
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
