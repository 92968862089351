import React from 'react'
import PersonProfile from 'blocks/PersonProfile/template'
import PartnersPagination from 'blocks/_dynamic/PartnersPagination/template'
import Footer from 'components/Footer'

const ContentType = ({ pageId, content, locale, layoutProps }) => {
  const strings = {
    en: {
      ourPartners: 'Our Partners',
    },
    zh: {
      ourPartners: '大师资源',
    },
  }
  const tr = strings[locale]

  const pageContent = {
    personProfile: {
      pretitle: tr.ourPartners,
      title: content.name,
      body: content.bio,
      image: content.image,
      subtitle: content.jobTitle,
      subtitle2: content.jobTitle2,
    },
    partnersPagination: {
      partner: content,
    },
  }
  return (
    <div className='page'>
      <PersonProfile content={pageContent.personProfile} isCirclePhoto={true} />
      <PartnersPagination
        content={pageContent.partnersPagination}
        locale={locale}
      />
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType
