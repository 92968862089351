import React from 'react'
import Inview from 'components/Inview'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  return (
    <div className={`pad-v-standard bg-shade no-collapse ${styles.component}`}>
      <div className='container'>
        <div className={styles.itemsWrap}>
          <Item content={content.prev} direction='prev' />
          <Item content={content.next} direction='next' />
        </div>
      </div>
    </div>
  )
}

export default Block

const Item = ({ content, direction }) => {
  const { pretitle, title, subtitle, image, link } = content
  const directionClass = direction === 'prev' ? styles.prev : styles.next
  return (
    <LinkWrap className={`${styles.item} ${directionClass}`} to={link}>
      <Inview className='fade-in up'>
        <div className={styles.image}>
          <ImageWrap image={image} />
        </div>
        <div className={styles.text}>
          <div className={styles.pretitle}>
            <h5>{pretitle}</h5>
            <div className={styles.line} />
          </div>
          <h3 className={styles.title}>{title}</h3>
          <h6>{subtitle}</h6>
        </div>
      </Inview>
    </LinkWrap>
  )
}
